<template>
  <section class="integraciones">
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200" v-show="view_permissions.read == true">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 mb-5">
            <div class="mt-5">
              <h2>Integraciones de {{ selected_bot.bot_name }}</h2>
              <p>
                Selecciona el chatbot con el que quieres realizar las integraciones. En el caso de
                los ecommerce, solo puedes realizar una a la vez.
              </p>
              <div class="row my-5">
                <div v-for="(other, index) in others" :key="`other-${ index }`">
                  <div v-show="other.show">
                    <label
                      :for="other.name"
                      :title="'Integrar ' + other.name"
                      :class="{
                        'card card-click': true,
                        'ig-card': other.name == 'instagram',
                        selected: selected == other.name
                      }"
                    >
                      <img
                        :class="{
                          icon_brand: true,
                          'ig-logo': other.name == 'instagram'
                        }"
                        :src="other.img_url"
                        :alt="other.name"
                      />
                    </label>
                    <input
                      type="radio"
                      class="not_here"
                      :id="other.name"
                      v-model="selected"
                      :value="other.name"
                    />
                  </div>
                </div>
                <div v-for="(ecommerce, index) in ecommerceFilter" :key="`ecommerce-${ index }`">
                  <div v-show="ecommerce.show">
                    <label
                      :for="ecommerce.name"
                      :title="'Integrar ' + ecommerce.name"
                      :class="{
                        'card card-click': true,
                        selected: selected == ecommerce.name
                      }"
                    >
                      <img class="icon_brand" :src="ecommerce.img_url" :alt="ecommerce.name" />
                    </label>
                    <input
                      type="radio"
                      class="not_here"
                      :id="ecommerce.name"
                      v-model="selected"
                      :value="ecommerce.name"
                    />
                  </div>
                </div>
                <div v-show="showVtexAuto" @click="editIntegrations(null, { provider:'vtex_auto' })">
                  <label
                    for="vtex_auto"
                    title="Integrar Automatizaciones Vtex"
                    :class="{
                      'card card-click': true,
                      selected: selected == 'vtex_auto'
                    }"
                  >
                    <img
                      class="icon_brand asdasd"
                      src="/img/logos/2-implementacion/vtex-automatizacion-2.svg"
                      alt="vtex_auto" />
                  </label>
                  <input
                    type="radio"
                    class="not_here"
                    id="vtex_auto"
                    v-model="selected"
                    value="vtex_auto"
                  />
                </div>
              </div>
              <EditDatabot :selected="selected" />
              <EditBsale
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditJumpseller
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditMagento
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditPrestashop
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditShopify
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditVtex
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditWhatsapp
                v-if="selected == 'whatsapp'"
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :view_permissions="view_permissions"
                @getWsp="wgtWhatsApp"
                @dontWsp="currentWsp = false"
                @closeView="selected = ''"
              />
              <EditInstagram
              v-if="selected == 'instagram'"
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :view_permissions="view_permissions"
                @getInsta="wgtInstaApp"
                @closeView="selected = ''"
              />
              <EditMessenger
              v-if="selected == 'messenger'"
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :view_permissions="view_permissions"
                @getMessenger="wgtMessengerApp"
                @closeView="selected = ''"
              />
              <EditWoocommerce
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditVtexAuto
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="selectedVtexAuto"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditEnviame
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="enviameCredential"
                :view_permissions="view_permissions"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditHubspot
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :hubspot_api_key="hubspot_api_key"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditBlueExpress
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
              <EditFazt
                :selected="selected"
                :bot_id="bot_id"
                :fields="resetFields"
                :integrations="integrations"
                @closeView="selected = ''"
                @getIntegrations="getIntegrationsByBot(bot_id)"
              />
            </div>
          </div>
          <div class="col-lg-4 justify-content-end mb-5">
            <h4 class="my-5">Integraciones activas</h4>
            <div class="scroll_box">
              <div
                class="card buefy card-click card-left"
                :class="{ selected: selected == 'databot' }"
                @click="selected = 'databot'"
              >
                <div class="label-box">
                  <label class="mb-0">Databot</label>
                  <div class="d-flex align-items-center">
                    <span class="text-state edit_hover mr-0">Activa</span>
                  </div>
                </div>
              </div>
              <div
                :key="`wsp-${ currentWsp }`"
                v-show="currentWsp"
                class="card buefy card-click card-left"
                :class="{ selected: selected == 'whatsapp' }"
                @click="selected = 'whatsapp'"
              >
                <div class="label-box">
                  <label class="mb-0">WhatsApp</label>
                  <div class="d-flex align-items-center">
                    <span v-if="view_permissions.delete == true" @click="deleteWhatsappIntegration()" class="edit_hover error_hover mr-3"
                      >Eliminar</span
                    >
                    <span class="text-state edit_hover mr-0">Activa</span>
                  </div>
                </div>
              </div>
              <div
                :key="`instagram-${ currentInsta }`"
                v-show="currentInsta"
                class="card buefy card-click card-left"
                :class="{ selected: selected == 'instagram' }"
                @click="selected = 'instagram'"
              >
                <div class="label-box">
                  <label class="mb-0">Instagram</label>
                  <div class="d-flex align-items-center">
                    <span
                      v-if="view_permissions.delete == true"
                      @click="deleteInstagramIntegration()"
                      class="edit_hover error_hover mr-3"
                      >Eliminar</span
                    >
                    <span class="text-state edit_hover mr-0">Activa</span>
                  </div>
                </div>
              </div>
              <div
                :key="`messenger-${currentMessenger}`"
                v-show="currentMessenger"
                class="card buefy card-click card-left"
                :class="{ selected: selected == 'messenger' }"
                @click="selected = 'messenger'"
              >
                <div class="label-box">
                  <label class="mb-0">Messenger</label>
                  <div class="d-flex align-items-center">
                    <span
                      v-if="view_permissions.delete == true"
                      @click="deleteMessengerIntegration()"
                      class="edit_hover error_hover mr-3"
                      >Eliminar</span
                    >
                    <span class="text-state edit_hover mr-0">Activa</span>
                  </div>
                </div>
              </div>
              <div v-show="integrations.length > 0">
                <div
                  v-for="(integration, integrationIndex) in integrations"
                  :key="`integration-${ integration.id }`"
                  :class="{
                    'card buefy card-click card-left': true,
                    selected: selected == integration.provider
                  }"
                  @click="editIntegrations(integration.provider, integration)"
                >
                  <template v-if="bot_id == integration.bot_id">
                    <div class="label-box">
                      <label
                        class="capitalize mb-0"
                        @click="editIntegrations(integration.provider, integration)"
                        >{{ integration.provider }}</label
                      >
                      <div class="d-flex align-items-center">
                        <span
                          v-if="view_permissions.delete == true"
                          @click="deleteIntegration(integration, integrationIndex, integration.id)"
                          class="edit_hover error_hover mr-3"
                          >Eliminar</span
                        >
                        <b-switch
                          v-model="integration.status"
                          @input="changeStatus(integration.status, integration.id)"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div v-show="vtexAuto.length > 0">
                <div
                  v-for="(integration, integrationIndex) in vtexAuto"
                  :key="`auto-${ integration.id }`"
                  :class="{
                    'card buefy card-click card-left': true,
                    selected: selected == integration.provider && selectedVtexAuto?.id == integration.id
                  }"
                  @click="editIntegrations(integration.provider, integration)"
                >
                  <template v-if="bot_id == integration.bot_id">
                    <div class="label-box">
                      <label
                        class="capitalize mb-0"
                        >Automatización - {{ JSON.parse(integration.credentials).name }}</label
                      >
                      <div class="d-flex align-items-center">
                        <span
                          v-if="view_permissions.delete == true"
                          @click="deleteVtexAuto(integration)"
                          class="edit_hover error_hover mr-3"
                          >Eliminar</span
                        >
                        <b-switch
                          v-model="integration.status"
                          @input="changeStatus(integration.status, integration.id)"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="my-4" v-show="ecommerceWarning">
              <p>
                <i class="fas fa-info-circle destacado" />Ya tienes un ecommerce integrado. Debes
                eliminar el actual para poder cambiar.
              </p>
            </div>
          </div>
          <div class="col-lg-6 mb-5">
            <router-link class="btn-text" to="/dashboard"
              >&lt;- Volver</router-link
            >
          </div>
          <div class="col-lg-4 mb-5" />
        </div>
      </div>
    </FadeTransition>
    <NoAccessView
      :show="!view_permissions.read && !loading"
      @loading="loading = false"
    />
  </section>
</template>

<script>
import EditDatabot from "./components/EditDatabot";
import EditBsale from "./components/EditBsale";
import EditJumpseller from "./components/EditJumpseller";
import EditPrestashop from "./components/EditPrestashop";
import EditMagento from "./components/EditMagento";
import EditShopify from "./components/EditShopify";
import EditVtex from "./components/EditVtex";
import EditWhatsapp from "./components/EditWhatsapp";
import EditInstagram from './components/EditInstagram';
import EditWoocommerce from "./components/EditWoocommerce";
import EditBlueExpress from "./components/EditBlueExpress";
import EditFazt from "./components/EditFazt";
import EditVtexAuto from "./components/EditVtexAuto";
import EditEnviame from "./components/EditEnviame";
import EditHubspot from "./components/EditHubspot";
import EditMessenger from "./components/EditMessenger";
import dashboard_api from "@/dashboard_api.js";
import { FadeTransition } from "vue2-transitions";
import { mapState, mapMutations } from "vuex";
import "@/assets/buefy_class.scss";
import axios from "axios";
import moment from "moment";
import environment from '@/environment.js';
import graphApiService from "@/services/api/graphApi.js";

export default {
  components: {
    FadeTransition,
    EditDatabot,
    EditBsale,
    EditJumpseller,
    EditPrestashop,
    EditMagento,
    EditShopify,
    EditVtex,
    EditWhatsapp,
    EditWoocommerce,
    EditInstagram,
    EditBlueExpress,
    EditFazt,
    EditVtexAuto,
    EditEnviame,
    EditHubspot,
    EditMessenger
  },
  data() {
    return {
      ecommerceFilter: [],
      ecommerceWarning: false,
      wsapId: 0,
      wabas: [], // whatsapp accounts from meta
      igIds: [],
      currentWhatsapp: {},
      currentWsp: false,
      currentInsta: false,
      currentMessenger: false,
      bot_id: null,
      loading: false,
      selected: "",
      providers: "",
      credentials: {},
      status: null,
      integrations: [],
      vtexAuto: [],
      enviameCredential: null,
      selectedVtexAuto: null,
      hubspot_api_key: null,
      showVtexAuto: false,
      resetFields: false,
      others: [
        {
          name: "databot",
          show: true,
          img_url: "/img/logos/2-implementacion/databot-api.svg"
        },
        {
          name: "whatsapp",
          show: true,
          img_url: "/img/logos/2-implementacion/whatsapp.svg"
        },
        {
          name: 'instagram',
          show: true,
          img_url: '/img/logos/2-implementacion/instagram3.svg',
        },
        {
          name: 'messenger',
          show: true,
          img_url: '/img/logos/2-implementacion/messenger.svg',
        },
        {
          name: "bsale",
          show: true,
          img_url: "/img/logos/2-implementacion/bsale-2.svg"
        },
        {
          name: "blue_express",
          show: true,
          img_url: "/img/logos/2-implementacion/blue.svg"
        },
        {
          name: "fazt",
          show: true,
          img_url: "/img/logos/2-implementacion/fazt.svg"
        },
        {
          name: "enviame",
          show: true,
          img_url: "/img/logos/2-implementacion/enviame.svg"
        },
        {
          name: "hubspot",
          show: true,
          img_url: "/img/logos/2-implementacion/hubspot.svg"
        }
      ],
      ecommerces: [
        {
          name: "jumpseller",
          show: true,
          img_url: "/img/logos/2-implementacion/jumpseller-2.svg"
        },
        {
          name: "magento",
          show: true,
          img_url: "/img/logos/2-implementacion/magento.svg"
        },
        {
          name: "prestashop",
          show: true,
          img_url: "/img/logos/2-implementacion/prestashop-2.svg"
        },
        {
          name: "shopify",
          show: true,
          img_url: "/img/logos/2-implementacion/shopify.svg"
        },
        {
          name: "vtex",
          show: true,
          img_url: "/img/logos/2-implementacion/vtex.svg"
        },
        {
          name: "woocommerce",
          show: true,
          img_url: "/img/logos/2-implementacion/woocommerce.svg"
        },
      ],
      messengerIds: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.bot_id = this.selected_bot.id;
    this.getIntegrationsByBot(this.bot_id);
    this.reloadFilterEcommerce();
  },
  mounted() {
    this.initialize();
    this.validatePlan();
    this.validatePlatform();
  },

  watch: {
    selected(val) {
      this.providers = val;
    },

    selected_bot(bot) {
      this.bot_id = bot.id;
      this.getIntegrationsByBot(this.bot_id);
      this.reloadFilterEcommerce();
      this.selected = "";
    },
    plan_name(val) {
      this.validatePlan();
    }
  },
  computed: {
    ...mapState(["user", "selected_bot", "plan_name", "platform_name", "view_permissions"])
  },

  methods: {
    ...mapMutations(['setEcommerceAccount', 'setProviders']),

    initialize() {
      // get bot_credentials to get wtsp and ig integrations
      dashboard_api.get(`/bot_credential/get_by_bot_id/${this.selected_bot.id}`).then(result => {
        const { data } = result;
        this.currentWsp = data.some(item => item.channel === 'whatsapp');
        this.currentInsta = data.some(item => item.channel === 'instagram');
        this.currentMessenger = data.some(item => item.channel === 'messenger');
      });
    },
    validatePlan() {
      if (this.plan_name == "Plan Basic-web0" || this.plan_name == "Plan Starter-web1") {
        this.ecommerceFilter = this.ecommerces.filter(el => el.name != "vtex");
      } else {
        this.ecommerceFilter = this.ecommerces;
      }
    },
    validatePlatform() {
      if (this.platform_name == "shopify") {
        this.$router.push({ name: "dashboard" });
      }
    },

    reloadFilterEcommerce() {
      this.ecommerceWarning = false;

      for (let el of this.ecommerces) {
        el.show = true;
      }
    },
    changeStatus(integrationStatus, integrationId) {
      this.status = integrationStatus;

      dashboard_api
        .put(`/user_credentials/${integrationId}`, {
          status: integrationStatus
        })
        .then(resp => {
          // console.log('resp', resp.data);

          this.$swal({
            icon: "success",
            title: `Integración ${integrationStatus ? "Activa" : "Deshabilitada"}`,
            timer: 1000,
            showConfirmButton: false
          });
        })
        .catch(error => console.log("error->", error));
    },

    editIntegrations(provider, integration) {
      this.selected = integration.provider;
      if (integration.provider === 'vtex_auto') {
        this.selectedVtexAuto = provider ? integration : null;
      }
    },
    deleteVtexAuto(integration) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "Estás a punto de eliminar esta integración",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Confirmar",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then(async result => {
        const { id } = integration;
        if (result.isConfirmed) {
          const events = await dashboard_api.get(`/events/events_by_bot/${this.selected_bot.id}`);
          if (events.data.find(event => event.credential.id == id)) {
            this.$swal({
              icon: "error",
              title: "Error al eliminar",
              text: "Elimine la automatización que está relacionada a la credencial"
            });
          } else {
            // Borrar en front
            this.vtexAuto = this.vtexAuto.filter(e => e.id != id);
            // Borrar en back
            dashboard_api.delete(`/user_credentials/${id}`);
            this.$swal({
              icon: "success",
              title: "Integración eliminada",
              timer: 1000,
              showConfirmButton: false
            });
            this.selected = "";
            this.resetFields = true;
          }
        }
        this.resetFields = false;
      });
    },
    deleteIntegration(integration, integrationIndex, integrationId) {
      this.$swal({
        title: "¿Estás seguro?",
        text: "Estás a punto de eliminar esta integración",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Confirmar",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then(async result => {
        if (result.isConfirmed) {
          const events = await dashboard_api.get(`/events/events_by_bot/${this.selected_bot.id}`);
          if (events.data.find(event => event.credential.id == integrationId)) {
            this.$swal({
              icon: "error",
              title: "Error al eliminar",
              text: "Elimine la automatización que está relacionada a la credencial"
            });
          } else {
            // Borrar en front
            this.integrations.splice(integrationIndex, 1);
            // Borrar en back
            dashboard_api.delete(`/user_credentials/${integrationId}`);

            if (integration.provider === "vtex") {
              await axios.post(`${ environment.url_env_bot }/delete-vtex-hook`, { botId: this.selected_bot.id, credential: integration });
              console.log("credencial borrada");
              localStorage.setItem("deleteTimer", moment());
            }

            this.$swal({
              icon: "success",
              title: "Integración eliminada",
              timer: 1000,
              showConfirmButton: false
            });
            this.selected = "";
            this.resetFields = true;

            if (integration.provider != "bsale" && integration.provider != "enviame") {
              this.reloadFilterEcommerce();
            }
          }
        }
      });
      this.resetFields = false;
    },

    deleteWhatsappIntegration() {
      this.$swal({
        title: "¿Estás seguro?",
        text: "Estás a punto de eliminar esta integración",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: false,
            visible: true,
            className: "",
            closeModal: true
          },
          confirm: {
            text: "Confirmar",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          }
        }
      }).then(result => {
        if (result.isConfirmed) {
          if (this.wabas.length > 0) {
            // remove all phone numbers for wtsp busiensses
            for (let waba of this.wabas) {
              const { bot_id, credentials } = waba;
              graphApiService.deleteWhatsAppConnection(bot_id, credentials.waba_id, credentials.access_token);
              dashboard_api.delete(`/bot_credential/delete/${waba.id}`).then(result => {
                console.log("Borrado: ", waba.id);
              });
            }
          } else {
            dashboard_api.delete(`/bot_credential/delete/${this.wsapId}`).then(result => {
          });
          }
          this.currentWsp = false;
          this.wabas = [];
          this.$swal({
              icon: "success",
              title: "Integración eliminada",
              timer: 1000,
              showConfirmButton: false
            });
          this.selected = "";
          this.resetFields = true;
        }
        this.resetFields = false;
      });
    },
    deleteInstagramIntegration() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Estás a punto de eliminar esta integración',
        icon: 'warning',
        showCancelButton: true,
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Confirmar',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(result => {
        if (result.isConfirmed) {
          for (let item of this.igIds) {
            const { meta_page_id, access_token } = item.credentials;
            graphApiService.deletePageConnection(item.bot_id, meta_page_id, 'instagram', access_token);
            dashboard_api.delete(`/bot_credential/delete/${item.id}`).then(result => {
              console.log("Borrado: ", item.id);
            });
          }
          this.currentInsta = false;
          this.$swal({
            icon: 'success',
            title: 'Integración eliminada',
            timer: 1000,
            showConfirmButton: false,
          });
          this.selected = '';
          this.resetFields = true;
        }
        this.resetFields = false;
      });
    },
    deleteMessengerIntegration() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Estás a punto de eliminar esta integración',
        icon: 'warning',
        showCancelButton: true,
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Confirmar',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(result => {
        if (result.isConfirmed) {
          for (let item of this.messengerIds) {
            const { meta_page_id, access_token } = item.credentials;
            graphApiService.deletePageConnection(item.bot_id, meta_page_id, 'messenger', access_token);
            dashboard_api.delete(`/bot_credential/delete/${item.id}`).then(result => {
              console.log("Borrado: ", item.id);
            });
          }
          this.currentMessenger = false;
          this.$swal({
            icon: 'success',
            title: 'Integración eliminada',
            timer: 1000,
            showConfirmButton: false,
          });
          this.selected = '';
          this.resetFields = true;
        }
        this.resetFields = false;
      });
    },
    wgtWhatsApp(currentWhatsapp) {
      if (currentWhatsapp.provider === 'meta') {
        this.wabas = JSON.parse(JSON.stringify(currentWhatsapp.wabas));
      } else {
        this.wsapId = currentWhatsapp.id;
      }
      this.currentWsp = true;
    },
    wgtInstaApp(currentInstagram) {
      this.igIds = currentInstagram;
      this.currentInsta = true;
    },
    wgtMessengerApp(currentMessenger) {
      this.messengerIds = currentMessenger;
      this.currentMessenger = true;
    },
    async getIntegrationsByBot(bot) {
      try {
        this.loading = true;
        this.integrations = (
          await dashboard_api.get(`/user_credentials/search_for_bot/${bot}`, { headers: { BOT_TOKEN: this.selected_bot.token, BOT_ID: this.selected_bot.id } })
        ).data;
        this.setProviders(this.integrations);

        if (this.integrations?.length > 0) {
          this.enviameCredential = this.integrations.filter(e => e.provider === 'enviame');
          this.vtexAuto = this.integrations.filter(e => e.provider === 'vtex_auto');
          this.hubspot_api_key = this.integrations.filter(e => e.provider === 'hubspot');
          this.integrations = this.integrations.filter(e => e.provider !== 'vtex_auto');

          let no_ecommerces = ['bsale', 'enviame', 'hubspot', 'blue_express', 'fazt'];

          for (let item of this.integrations) {
            if (!no_ecommerces.includes(item.provider)) {
              for (let el of this.ecommerces) {
                // console.log("3->", el);
                if (el.name != item.provider) {
                  this.ecommerceWarning = true;
                  el.show = false;
                }
              }
            }
            if (item.provider === 'vtex') this.showVtexAuto = true;
            // if (!no_ecommerces.includes(item.provider)) {
            //   this.setEcommerceAccount({
            //     show_bar: true,
            //     show_done_bar: false,
            //     upload_progress: 10
            //   });
            // };
          }
        }
        if (this.integrations?.length == 0) this.resetFields = true;
        this.loading = false;
      } catch (error) {
        console.log("getIntegrationsByBot error->", error);
        this.integrations = [];
        this.loading = false;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.integraciones {
  width: 100%;

  .card {
    padding: 1.25rem 2rem;
    margin-left: 0;
    margin-right: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(.buefy) {
      width: 160px;
      height: 65px;
    }
    @media (max-width: 1600px) {
      padding: 1.25rem 2.25rem;
    }
  }
  .bg_grey {
    cursor: pointer;

    &:hover,
    &:focus {
      border: 1px solid #2981ef !important;
    }
  }
  .scroll_box {
    max-height: 650px;
    padding: 0 5px 0.25rem 0 !important;
    overflow-y: scroll;

    @media (max-width: 1600px) {
      max-height: 510px;
    }
  }
  .edit_hover {
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
  .scroll_box .card {
    padding: 1rem;
  }
  .card-click:hover div .edit_hover {
    opacity: 1;
  }
  .card-click:hover div label {
    cursor: pointer;
  }
  .text-state {
    padding: 0 0.75rem;
  }
  .ig-card {
    padding: 0 2rem !important;
  }
  .ig-logo {
    width: 90px;
  }
}
</style>
