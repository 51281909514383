import * as echarts from 'echarts';

export default {
  methods: {
    multiBarChart(month, first_series, second_series, element, title, names_series) {
      let chartDom = document.getElementById(element);

      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      window.addEventListener('resize', () => {
        myChart.resize();
      });

      myChart.setOption({
        title: {
          text: title,
          textStyle: {
            fontSize: 15,
            fontWeight: 700,
            fontFamily: "Manrope, sans-serif",
            color: ['#181818'],
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
            magicType: {
              type: ['line', 'bar', 'stack'],
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            let tooltipText = `<span class="text_sm">${params[0].name}</span><br/>`;
            
            params.forEach((item, index) => {              
              tooltipText += `${item.marker} ${names_series[index]}: <label class="mb-0 ml-2">${item.value}</label><br/>`;
            });
            return tooltipText;
          }
        },
        xAxis: {
          data: month
        },
        yAxis: {},
        series: [
          {
            name: names_series[0],
            type: 'bar',
            data: first_series,
            barWidth: '20%',
            itemStyle: {
              color: '#cdcdcd',
              // shadowColor: '#91cc75',
              borderType: 'dashed',
              opacity: 0.75
            }
          },
          {
            name: names_series[1],
            type: 'bar',
            data: second_series,
            itemStyle: {
              color: '#2981EF',
              borderType: 'dashed',
              opacity: 0.75
            }
          }
        ]
      });
    }
  },
};
