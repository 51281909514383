import * as echarts from 'echarts';

export default {
  methods: {
    multiBarChart2(months, series, id, title, names_series) {
      let chartDom = document.getElementById(id);
      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      window.addEventListener('resize', () => {
        myChart.resize();
      });

      let set_series = [];
      let new_obj = {};
      const color = ['#cdcdcd', '#2981EF', '#E3F0FF', '#71B1FF', '#e8e8e8', '#767676', '#b2b2b2', '#89BEFF'];
      
      series.forEach((el, index) => {
        new_obj = {
          name: names_series[index],
          type: 'bar',
          data: el,
          barWidth: '5%',
          itemStyle: {
            color: color[index],
            // shadowColor: '#91cc75',
            borderType: 'dashed',
            opacity: 0.75
          }
        };
        set_series.push(new_obj);
      });

      myChart.setOption({
        title: {
          text: title,
          textStyle: {
            fontSize: 15,
            fontWeight: 700,
            fontFamily: "Manrope, sans-serif",
            color: ['#181818'],
          },
        },
        toolbox: {
          feature: {
            saveAsImage: {},
            magicType: {
              type: ['line', 'bar', 'stack'],
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            let tooltipText = `<span class="text_sm">${params[0].name}</span><br/>`;
            
            params.forEach((item, index) => {              
              tooltipText += `${item.marker} ${names_series[index]}: <label class="mb-0 ml-2">${item.value}</label><br/>`;
            });
            return tooltipText;
          }
        },
        xAxis: {
          data: months
        },
        yAxis: {},
        series: set_series
      });
    }
  },
};
