<template>
  <div :class="`col-xl-${columns} mb-3`">
    <FadeTransition :duration="200">
      <div class="data-card-welcome index">
        <div class="row w-100 justify-content-between align-items-center">
          <div class="col-lg-4 my-auto pl-0 pr-4">
            <h3 v-if="title" class="mb-2">{{ title }}</h3>
            <p class="custom_width mb-0">Estas son las conversaciones y leads que has tenido hoy.</p>
          </div>
          <div class="col-lg-8 px-0">
            <div class="row align-items-center">
              <template v-if="model?.length > 0">
                <div v-for="(item, index) in model" :key="index" class="my-2 mr-5">
                  <span class="number_box">{{ item.value }}</span>
                  <span>{{ item.label }}</span>
                </div>
                <div class="my-2 mr-5">
                  <span class="text_sm w-100 py-2">
                    <router-link :to="databotLiveValidation">
                      Ver conversaciones ->
                    </router-link>
                  </span>
                </div>
              </template>
            </div>
          </div>
        </div>
        <img class="bg_float" src="/img/banners/bg_welcome.svg" alt="">
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import DataChart from '../../components/builders/DataChart';
import { FadeTransition } from "vue2-transitions";
import { mapState } from "vuex";

export default {
  components: {
    DataChart,
    FadeTransition
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    columns: {
      type: Number,
      default: () => 3
    },
    model: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(["selected_bot"]),

    databotLiveValidation() {
      return this.selected_bot.is_databot_live_3 ? '/databot-live-2' : '/databot-live';
    }
  }
}
</script>

<style lang="scss" scoped>
.data-card-welcome {
  position: relative;
  min-width: fit-content;
  max-width: 100%;
  width: 100%;
  height: 150px;
  padding: 1.5rem;
  border: 1px solid #e8e8e8;
  // border: 1px solid #bfdbff;
  // background: linear-gradient(180deg, #ffffff 0%, #f8fbff 100%);
  background: #fff;
  border-radius: 1rem;
  transition: .25s;
  display: flex;
  align-items: center;
  margin: .5rem 0;
  z-index: 0;
  overflow: hidden;

  @media (max-width: 991px) {
    height: 100%;

    p {
      margin-bottom: 1.5rem !important;
    }
  }
  .index {
    position: relative;
    z-index: 3;
  }
  .custom_width {
    text-wrap: balance;
  }
  .text-state {
    display: inline-block;
    max-width: 200px;
    width: fit-content;
    padding-inline: 1rem;
  }
  .bg_float {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    height: 100%;
    border-radius: 0 1rem 1rem 0;
    z-index: -1;
  }
}
</style>