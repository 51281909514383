<template>
  <div :class="customClasses">
    <div class="no_data-card" v-show="loading.includes(chart_id)">
      <SpinnerLoading />
    </div>
    <FadeTransition :duration="200">
      <div
        v-show="!loading.includes(chart_id)"
        :class="{
          'data-card-cart': true,
          sm: size == 'sm',
          hover_card: hover,
          column: direction == 'column',
          bg_color: type == 'banner'
        }"
      >
        <template v-if="type != 'banner'">
          <template v-if="tooltip.length > 0">
            <span class="float_icon">?</span>
            <span class="tooltip_text-sm">{{ tooltip }}</span>
          </template>
          <div :class="{ 'body_box': true, 'mr-4': chat_bars }">
            <div>
              <h4 v-if="title" :class="{ 'mb-0': true, 'mb-2': !label && !last_month_value }">{{ title }}</h4>
              <p v-if="label" class="label mb-0">{{ label }}</p>
              <p v-if="type == 'percent'" class="quantity mb-1">{{ setFormatNumber(current_month_value) }}<span class="symbol ml-1 mb-0">%</span></p>
              <p v-else-if="type == 'price'" class="quantity mb-1">{{ setFormatNumber(current_month_value) }}<span class="symbol ml-1 mb-0">{{ lang != 'es' && lang != 'CLP' ? ' USD' : ' CLP' }}</span></p>
              <p v-else-if="type == 'time'" class="quantity mb-1">{{ current_month_value }}<span class="symbol ml-1 mb-0"></span></p>
              <p v-else class="quantity mb-1">{{ setFormatNumber(current_month_value) }}</p>
            </div>
            <template v-if="last_month_value && type != 'time'">
              <div v-if="comparison" :class="{ percent: true, positive: current_month_value > last_month_value }">
                <span class="fas fa-sort-up mr-1 mt-1" />+{{ diferencePercent }}%
              </div>
              <div v-else class="percent negative">
                <span class="fas fa-sort-down mr-1 mb-1" />-{{ diferencePercent }}%
              </div>
            </template>
          </div>
          <div class="chart" v-if="chart_id" :id="chart_id"></div>
          <div v-else-if="icon" :class="{ 'icon-box': true, 'ml-3': direction != 'column', price: icon == 'price' }">
            <i v-if="icon == 'price'" class="fas fa-money-bill" />
            <i v-else-if="icon == 'cart'" class="fas fa-shopping-cart" />
            <i v-else-if="icon == 'conversation'" class="fas fa-comment-alt" />
          </div>
        </template>
        <!-- only for banner type -->
        <template v-else>
          <div class="banner_box">
            <div class="col-8 px-0">
              <p class="text_sm mb-1">{{ label }}</p>
              <h4 class="mb-2">{{ title }}</h4>
              <template v-if="path">
                <router-link :to="path" class="text_sm link">
                  Ver más ->
                </router-link>
              </template>
            </div>
            <img src="/img/icons/48-i-carousel.svg" class="icon" alt="">
          </div>
        </template>
      </div>
    </FadeTransition>
  </div>
</template>

<script>
import DataChart from '../../components/builders/DataChart';
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DataChart,
    FadeTransition
  },
  props: {
    current_month_value: {
      default: () => 0
    },
    last_month_value: {
      default: () => null
    },
    chart_id: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    lang: {
      type: String,
      default: () => 'es'
    },
    size: {
      type: String,
      default: () => ''
    },
    model: {
      type: Object,
      default: () => {}
    },
    icon: {
      type: String,
      default: () => ''
    },
    columns: {
      type: Number,
      default: () => 3
    },
    direction: {
      type: String,
      default: () => 'row'
    },
    chat_bars: {
      type: Boolean,
      default: () => false
    },
    hover: {
      type: Boolean,
      default: () => false
    },
    tooltip: {
      type: String,
      default: () => ''
    },
    loading: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: () => ''
    },
    classes: {
      type: Array,
      default: () => ['']
    }
  },
  computed: {
    customClasses() {
      const base = [`col-xl-${this.columns} mb-3`];
      let custom = [];

      return this.classes.length > 0 ? custom = [...base, ...this.classes] : base;
    },
    diferencePercent() {
      const old = this.last_month_value;
      const now = this.current_month_value;
      let percent = 0;

      // Porcentaje de diferencia vs registro anterior
      if (old === 0 && now === 0) return percent = 0;
      else if (old > 0 && now === 0) return percent = ((old * 100) / 100).toFixed(0);
      else return percent = (((now - old) / old ) * 100).toFixed(0);
    },
    comparison() {
      const current = Number(this.current_month_value);
      const last = Number(this.last_month_value);

      return current >= last;
    }
  },
  methods: {
    percentFormat(number) {
      let value = number;
      return number > 1 ? number : number = value * 100;
    },
    setFormatNumber(number) {
      switch (this.type) {
        case "price":
          return this.lang != 'es' && this.lang != 'CLP' ? Intl.NumberFormat('en-US').format(number) : Intl.NumberFormat('de-DE').format(number);
        case "percent":
          return this.percentFormat(number);
        default:
          return Intl.NumberFormat('de-DE').format(number);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.data-card-cart {
  position: relative;
  min-width: 200px;
  max-width: 100%;
  width: 100%;
  --h_base: 150px;
  max-height: var(--h_base);
  height: var(--h_base);
  padding: 1.5rem;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  transition: .25s;
  margin: .5rem 0;

  h4 {
    font-size: 14px;
  }
  &.hover_card:hover {
    transform: translate(-0.25rem, -0.25rem);
    box-shadow: 1rem 1rem 30px #D8EAFF;
  }
  &:hover {
    .fa-sort-up {
      animation: 1s hover-up ease-in-out infinite;
    }
    .fa-sort-down {
      animation: 1s hover-down ease-in-out infinite;
    }
    .float_icon {
      opacity: 1;

      &:hover {
        + .tooltip_text-sm {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  .icon-box, .chart {
    width: 105px;
    height: 105px;
    min-width: 105px;
    max-width: 100%;
    border-radius: .75rem;
    padding: .75rem 1rem;
    // background-color: #E3F0FF;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      width: 100%;
      text-align: center;
      font-size: 2.5rem;
      color: #BFDCFF;
      margin: 0 auto;
    }
  }
  .chart {
    background-color: transparent;
    padding: 0;
    margin-top: .25rem;
    position: absolute;
    top: auto;
    right: .5rem;
    bottom: auto;
    z-index: 0;
  }
  .icon-box.price {
    // background-color: #DDFFDB;
    background-color: #fafafa;

    i {
      color: #7AD676;
    }
  }
  .body_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    font-size: 12px;
  }
  .label {
    font-size: 11px;
    color: #767676;
  }
  .quantity {
    font-size: 1.5rem;
    font-weight: 700;
    color: #181818;
  }
  .percent {
    width: fit-content;
    display: flex;
    align-items: center;
    padding: .15rem .4rem;
    border-radius: .5rem;
    color: #767676;
    background-color: #f2f2f2;
    font-size: 12px;
    
    &.positive {
      color: #34a82f;
      background-color: #DDFFDB;
      // background-color: #E3F0FF;
      
      span {
        color: #34a82f;
        // color: #2981EF;
      }
    }
    &.negative {
      color: #ff3860;
      background-color: #FFE3E3;

      span {
        color: #ff3860;
      }
    }
  }
  &.sm {
    padding: 1rem;
    border-radius: 1rem;
  }
  .symbol {
    font-size: 1rem;
    color: #cdcdcd;
  }
  // Card vertical mod
  &.column {
    flex-direction: column !important;
    max-height: 100%;
    height: 100%;
    min-width: fit-content;
    width: 100%;

    .body_box {
      justify-content: flex-start;
    }
    .icon-box {
      min-width: auto;
      width: 100%;
      height: 100%;
      margin-top: 1rem;
    }
  }
  .float_icon {
    opacity: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1.25rem;
    right: 1.75rem;
    width: 1rem;
    height: 1rem;
    border-radius: 10px;
    background-color: #181818;
    color: #fff;
    font-weight: 700;
    transition: .25s;
    font-size: 10px;
    cursor: pointer;
    z-index: 50;
  }
  .tooltip_text-sm {
    background-color: #2f2f2f;
    top: 1.25rem;
    left: auto;
    right: 1.75rem;
    max-width: 300px;
    font-size: 12px;
    z-index: 55;

    &:hover {
      opacity: 1;
      visibility: visible;
    }
    &::after {
      border-width: 0;
      border-style: none;
      border-color: transparent;
    }
  }
  &.bg_color {
    background: linear-gradient(180deg, #ffffff 0%, #f8fbff 100%);
    border-color: #71B1FF;
  }
  .banner_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .icon {
      position: relative;
      width: 120px;
      height: 100%;
      right: -1rem;
      filter: drop-shadow(5px 5px 20px #DEEDFF);
    }
  }
}
.no_data-card {
  width: 100%;
  background-color: #fafafa;
  color: #767676;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: 150px;
  border: 1px solid #e8e8e8;
  border-radius: 1rem;
  margin: .5rem 0;

  .bg-spinner {
    position: relative;
    height: 100%;
  }
}
@keyframes hover-up {
  0% { transform: translateY(0)}
  50% { transform: translateY(-4px) }
  100% { transform: translateY(2px) }
}
@keyframes hover-down {
  0% { transform: translateY(0)}
  50% { transform: translateY(4px) }
  100% { transform: translateY(-2px) }
}
</style>