<template>
  <div class="data-grid-row" :style="`--items: ${items}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Number,
      default: () => 1
    }
  }
}
</script>

<style lang="scss" scoped>
.data-grid-row {
  width: 100%;
  padding-inline: 1rem;
  display: grid;
  grid-template-columns: repeat(var(--items), 1fr);
  grid-gap: 1.75rem;
  flex-wrap: wrap;

  .item {
    max-width: 100% !important;
    padding-inline: 0;
    border-bottom: 0 !important;
    padding: 0 !important;
  }
  @media (max-width: 1279px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    column-gap: 1.75rem;
    row-gap: 0;

    .item-1 {
      grid-column: 1 / 2;
      grid-row: 1;
    }
    .item-2 {
      grid-column: 2 / 2;
      grid-row: 1;
    }
    .item-3 {
      grid-column: 1 / 2;
      grid-row: 2;
    }
    .item-4 {
      grid-column: 2 / 2;
      grid-row: 2;
    }
    .item-5 {
      grid-column: 1 / span 2;
      grid-row: 3;
    }
    .item-6 {
      grid-column: 2 / 2;
      grid-row: 3;
    }
  }
  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    .item-1 {
      grid-column: 1 / 1;
      grid-row: 1;
    }
    .item-2 {
      grid-column: 2 / 1;
      grid-row: 2;
    }
    .item-3 {
      grid-column: 1 / 1;
      grid-row: 3;
    }
    .item-4 {
      grid-column: 2 / 1;
      grid-row: 4;
    }
    .item-5 {
      grid-column: 1 / 1;
      grid-row: 5;
    }
    .item-6 {
      grid-column: 2 / 1;
      grid-row: 6;
    }
  }
}
</style>